import React from "react"
import Layout from '../components/layout-en'
import Head from '../components/head'
import {Row, Col} from 'react-bootstrap'
import { Link } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Slider from 'infinite-react-carousel';
import { /*StaticQuery,*/ graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import '../styles/index.css'

import rightArrow1 from '../images/right-arrow.png'
import rightArrow2 from '../images/long-arrow-right.png'



import NEURAFARM from '../images/Neurafarm-Logo-Samping.png'
import Vegetables from '../images/vegetables.png'
import Cabbages from '../images/cabbage.png'
import OrangeTree from '../images/orange_tree.png'

export const query = graphql`{
  LandingImg: file(relativePath: {eq: "landingImg.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FarmingImg: file(relativePath: {eq: "farming.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FoodSystemImg: file(relativePath: {eq: "food-system.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  DataDrivenImg: file(relativePath: {eq: "data-driven.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  NextGenImg: file(relativePath: {eq: "next-generation.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  PlanetImg: file(relativePath: {eq: "planet.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  PeopleImg: file(relativePath: {eq: "people.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FarmerImg: file(relativePath: {eq: "farmer_group.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoITB: file(relativePath: {eq: "logo-itb.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoLPIK: file(relativePath: {eq: "logo-lpik.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoGK: file(relativePath: {eq: "logo-gk.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoCISCO: file(relativePath: {eq: "logo-cisco-2.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoSIF: file(relativePath: {eq: "logo-SIF.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoNVIDIA: file(relativePath: {eq: "logo-nvidia.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTELKOMSEL: file(relativePath: {eq: "logo-telkomsel.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTFF: file(relativePath: {eq: "logo-tff.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTINC: file(relativePath: {eq: "logo-tinc.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoRISTEK: file(relativePath: {eq: "logo-ristek.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoRESET: file(relativePath: {eq: "logo-reset.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoCNN: file(relativePath: {eq: "logo-cnn.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoDailySocial: file(relativePath: {eq: "logo-daily-social.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoKabarTani: file(relativePath: {eq: "logo-kabartani.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoInfoKomputer: file(relativePath: {eq: "logo-infokomputer.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoKompas: file(relativePath: {eq: "logo-kompas.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoLiputan6: file(relativePath: {eq: "logo-liputan6.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoMediaIndonesia: file(relativePath: {eq: "logo-mediaindonesia.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTechInAsia: file(relativePath: {eq: "logo-techinasia.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  LogoTempo: file(relativePath: {eq: "logo-tempo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Vegetables: file(relativePath: {eq: "vegetables.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Cabbages: file(relativePath: {eq: "cabbage.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  OrangeTree: file(relativePath: {eq: "orange_tree.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`


class Home extends React.Component {

  constructor(props){
    super(props);
    this.listener = null;
    this.state = {
        status: "section1",
        email : '',
        result: null,
        resultMsg : null
    };
  }

  
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    this.setState({result: result.result})
    
    console.log(this.state.result)

    if(this.state.result === "success"){
      this.setState({resultMsg:"Thank you for subscribing"})
      this.setState({email : ''})
    } else {
      this.setState({resultMsg:"Subscription failed. Your e-mail has been registered"})
    }
  }

  handleChange = event => {
      this.setState({ email: event.target.value })
    }

  /*handleEmailChange = (event) => {
    this.setState({email : event.target.value})
    console.log(this.state.email);
  }*/

  componentDidMount(){
    /* https://codedaily.io/tutorials/80/Sticky-Header-with-Highlighting-Sections-on-Scroll
    https://stackoverflow.com/questions/59510990/how-to-change-navbar-background-color-in-react-when-i-scroll*/
    this.listener = document.addEventListener("scroll", e => {
        var scrolled = document.scrollingElement.scrollTop;
        if (scrolled >= 500 && scrolled <= 1200){
            if (this.state.status !== "section2"){
                this.setState({status : "section2"});
            }
        } else if (scrolled > 1200 && scrolled <= 2400){
            if (this.state.status !== "section3"){
                this.setState({status : "section3"});
            }
        } else if (scrolled > 2400){
            if (this.state.status !== "section4"){
                this.setState({status : "section4"});
            }
        } else {
            if (this.state.status !== "section1"){
                this.setState({status : "section1"});
            }
        }
    });
  }

  componentDidUpdate(){
    document.removeEventListener("scroll", this.listener);
  }

  render(){
    
    const settings =  {
      adaptiveHeight: true,
      arrows: false,
      centerMode: true,
      dots: true,
      duration: 20
    };

    console.log(this.props.data)

    return (
      <Layout>
        <Head title = "More Precise, More Impact"/>
        <div id = "home-page-container">
          <div id = "section-container">
            <div id = "section-1">
              <div id = "section-1-col-1">
                <div id = "section-1-col-1-img">
                  <GatsbyImage
                    image={this.props.data.LandingImg.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    alt = "Landing-img" />
                </div>
              </div>
              <div id = "section-1-col-2">
                <div id = "section-1-col-2-circle" />
                <div id = "section-1-col-2-container">
                  <div id = "primary-title">It's Time</div>
                  <div id = "secondary-title">
                    <div className = "line">To revolutionize the Agriculture Industry</div>
                    <div className = "line">To Achieve Global Food Security</div>
                    <div className = "line">To build a sustainable agriculture</div>
                    <div className = "line">To create a more resilient food system</div>
                  </div>
                  <div id = "content">
                    <p>How to feed the world? How to grow enough food for 
                      everyone on the planet? How to create a more 
                      sustainable agriculture?
                    </p>

                    <p>These are the questions we’re answering here at Neurafarm. 
                      Join our journey to empower farmer and business to 
                      produce more, reach more, and distribute better.
                    </p>

                    <div id = "read-more">
                      <Link to = "/what-we-do" id = "read-more-link">
                        <span id = "read-more-link-span">read more about our mission</span> 
                        <img id = "read-more-wwd" src = {rightArrow1} alt = "right-arrow" width = "18px"/>
                      </Link>
                    </div>                          
                  </div>
                </div>
              </div>
            </div>

            <div id = "section-2">
              <div id = "slider-container">
                <Slider { ...settings }>
                  <div className = "section-2-content-container">
                    <div className = "section-2-content-main">
                      <div className = "section-2-content-title">Empowering Farmers</div>
                      <div className = "section-2-content-paragraph">
                        All around the world, small farms are playing a big role in feeding the world. 
                        Ironically, more than 70% of the world’s poor are farmers. Increasing yields, 
                        reducing risks, and helping them to distribute crop yield puts more money directly 
                        into their pockets.
                      </div>

                      <Link to="/dokter-tania" id = "section-2-link">
                        Learn how we do it with Dokter Tania
                        <img src = {rightArrow2} className = "read-more-arrow" alt = "right-arrow" width = "18px"/>
                      </Link>
                    </div>
                    
                    <div className = "section-2-content-img">
                      <GatsbyImage
                        image={this.props.data.FarmingImg.childImageSharp.gatsbyImageData}
                        objectFit="cover"
                        alt = "farming-img" />
                    </div>
                  </div>
                    
                  <div className = "section-2-content-container">
                    <div className = "section-2-content-main">
                      <div className = "section-2-content-title">Building a more robust food system</div>
                      <div className = "section-2-content-paragraph">
                        Our multidisciplinary approach – which combines agriculture engineering, 
                        software and machine learning – is key to our vision to help achieve food 
                        security by empowering innovators across the food and agriculture supply chain.
                      </div>

                      <Link to="/dokter-tania" id = "section-2-link">
                        Learn how we do it with Dokter Tania
                        <img id = "read-more-drtania" src = {rightArrow2} className = "read-more-arrow" alt = "right-arrow" width = "18px"/>
                      </Link>
                    </div>
                    
                    <div className = "section-2-content-img">
                      <GatsbyImage
                        image={this.props.data.FoodSystemImg.childImageSharp.gatsbyImageData}
                        objectFit="cover"
                        alt = "food-system-img" />
                    </div>
                  </div>

                    <div className = "section-2-content-container">
                    <div className = "section-2-content-main">
                      <div className = "section-2-content-title">Smart and Data-Driven Farming</div>
                      <div className = "section-2-content-paragraph">
                        At Neurafarm, we believe that technology could solve even the world's biggest challenge. 
                        Including food security. We also believe that smart and data-driven farming is 
                        something that all farmers can do.
                      </div>
                      <Link to="/dokter-tania" id = "section-2-link">
                        Learn how we do it with Dokter Tania
                        <img src = {rightArrow2} className = "read-more-arrow" alt = "right-arrow" width = "18px"/>
                      </Link>
                    </div>
                    
                    <div className = "section-2-content-img">
                      <GatsbyImage
                        image={this.props.data.DataDrivenImg.childImageSharp.gatsbyImageData}
                        objectFit="cover"
                        alt = "data-driven-img" />
                    </div>
                  </div>

                  <div className = "section-2-content-container">
                    <div className = "section-2-content-main">
                      <div className = "section-2-content-title">Inspiring Next Generations of Food Growers</div>
                      <div className = "section-2-content-paragraph">
                        The amount of total land devoted to agricultural use continues to 
                        decline in all over the world, farmers community is declining as well. 
                        How can we inspire next generations of food growers to enter agriculture industry?
                      </div>
                      <Link to="/dokter-tania" id = "section-2-link">
                        Learn how we do it with Dokter Tania
                        <img src = {rightArrow2} className = "read-more-arrow" alt = "right-arrow" width = "18px"/>
                      </Link>
                    </div>
                    
                    <div className = "section-2-content-img">
                      <GatsbyImage
                        image={this.props.data.NextGenImg.childImageSharp.gatsbyImageData}
                        objectFit="cover"
                        alt = "next-gen-img" />
                    </div>
                  </div>

                </Slider>
                
              </div>
            </div>

            <div id = "section-3">
              <Row>
                <Col md = {4} className = "section-3-col">
                  <div className = "section-3-img">
                    <GatsbyImage
                      image={this.props.data.PlanetImg.childImageSharp.gatsbyImageData}
                      alt = "Planet-img" />
                  </div>
                  
                  <div className = "section-3-col-title">
                    Better for the Planet
                  </div>

                  <div className = "section-3-col-content">
                    As we rely on industrial agricultural commodities such as palm, soy, 
                    and industrial produced meat and dairy, we are losing forests and 
                    accelerating a climate and ecological emergency. Increasing productivity 
                    per m2 will halt deforestation, and better use of resources.
                  </div>
                </Col>
                
                <Col md = {4}  className = "section-3-col">
                  <div className = "section-3-img">
                    <GatsbyImage
                      image={this.props.data.PeopleImg.childImageSharp.gatsbyImageData}
                      alt = "People-img" />
                  </div>
        
                  <div className = "section-3-col-title">
                    Better for People
                  </div>

                  <div className = "section-3-col-content">
                    Healthier, tastier produce and food benefit everyone, and Dokter Tania makes it possible.
                  </div>
                </Col>
                

                <Col md = {4} className = "section-3-col col-khusus">

                  <div className = "section-3-img">
                    <GatsbyImage
                      image={this.props.data.FarmerImg.childImageSharp.gatsbyImageData}
                      alt = "Farmer-img" />
                  </div>

                  <div className = "section-3-col-title">
                    Better for Food Growers
                  </div>

                  <div className = "section-3-col-content">
                    By democratizing technology and knowledge, we would help food growers 
                    improve productivity while using less resources including land area and 
                    chemical. Hence, reducing risks, costs, and improving overall yield.
                  </div>
                </Col>
              </Row>
            </div>

            <div id = "section-4">
              <div className = "section-title">
                <img src = {NEURAFARM} alt = "neurafarm-logo" width = "150px"/>
                <span>is supported by</span>
              </div>

              <div className = "section-divider" />

              <div id = "section-4-sponsor-container">
                  <div className = "wwa-sponsors">
                    <div className = "wwa-sponsor-img">
                      <GatsbyImage
                        image={this.props.data.LogoITB.childImageSharp.gatsbyImageData}
                        alt = "logo-ITB" />
                    </div>

                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoLPIK.childImageSharp.gatsbyImageData}
                          alt = "logo-LPIK" />
                      </div>

                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoTINC.childImageSharp.gatsbyImageData}
                          alt = "logo-TINC" />
                      </div>

                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoGK.childImageSharp.gatsbyImageData}
                          alt = "logo-GK" />
                      </div>

                      

                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoNVIDIA.childImageSharp.gatsbyImageData}
                          alt = "logo-NVIDIA" />
                      </div>

                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoTELKOMSEL.childImageSharp.gatsbyImageData}
                          alt = "logo-TELKOMSEL" />
                      </div>

                      
                      
                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoRISTEK.childImageSharp.gatsbyImageData}
                          alt = "logo-RISTEK" />
                      </div>

                      <div className = "wwa-sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoTFF.childImageSharp.gatsbyImageData}
                          alt = "logo-TFF" />
                      </div>

                      <div className = "sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoCISCO.childImageSharp.gatsbyImageData}
                          alt = "logo-CISCO" />
                      </div>

                      <div className = "sponsor-img">
                        <GatsbyImage
                          image={this.props.data.LogoSIF.childImageSharp.gatsbyImageData}
                          alt = "logo-SIF" />
                      </div>

                  </div>                    
                
              </div>
              
            </div>

            <div id = "section-5">
              <div className = "section-title">                
                <span>What they say about</span>
                <img src = {NEURAFARM} alt = "neurafarm-logo" width = "150px"/>
              </div>

              <div className = "section-divider" />

              <div id = "section-5-sponsor-container">
                <div className = "sponsors">
                  <div className = "sponsor-img">
                    <a href = "https://dailysocial.id/post/introducing-neurafarm-the-doctor-for-agriculture-plants">
                      <GatsbyImage
                        image={this.props.data.LogoDailySocial.childImageSharp.gatsbyImageData}
                        alt = "logo-DailySocial" />
                    </a>
                  </div>

                  <div className = "sponsor-img">
                    <a href = "https://en.reset.org/blog/dr-tania-indonesian-ai-chatbot-helps-farmers-identify-and-treat-crop-disease-06082020">
                      <GatsbyImage
                        image={this.props.data.LogoRESET.childImageSharp.gatsbyImageData}
                        alt = "logo-Reset" />
                    </a>
                  </div>

                  <div className = "sponsor-img">
                    <a href = "https://thoughtforfood.org/content-hub/the-power-of-neurafarm-supporting-indonesian-farms-with-digital-ag/">
                      <GatsbyImage
                        image={this.props.data.LogoTFF.childImageSharp.gatsbyImageData}
                        alt = "logo-TFF" />
                    </a>
                  </div>

                  <div className = "sponsor-img">
                    <a href = "https://id.techinasia.com/akselerator-plug-and-play-indonesia-batch-kelima">
                      <GatsbyImage
                        image={this.props.data.LogoTechInAsia.childImageSharp.gatsbyImageData}
                        alt = "logo-TechInAsia" />
                    </a>
                  </div>

                  <div className = "sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoMediaIndonesia.childImageSharp.gatsbyImageData}
                      alt = "logo-MI" />
                  </div>

                  <div className = "sponsor-img">
                    <a href = "https://majalah.tempo.co/read/inovasi/159240/dokter-digital-para-petani">
                      <GatsbyImage
                        image={this.props.data.LogoTempo.childImageSharp.gatsbyImageData}
                        alt = "logo-Tempo" />
                    </a>
                  </div>
                  
                  <div className = "sponsor-img">
                    <a href = "https://infokomputer.grid.id/read/121596971/neurafarm-memerangi-hama-tanaman-dengan-artificial-intelligence">
                      <GatsbyImage
                        image={this.props.data.LogoInfoKomputer.childImageSharp.gatsbyImageData}
                        alt = "logo-InfoKomputer" />
                    </a>
                  </div>

                  <div className = "sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoLiputan6.childImageSharp.gatsbyImageData}
                      alt = "logo-Liputan6" />
                  </div>
                  
                  <div className = "sponsor-img">
                    <a href = "https://kabartani.com/dr-tania-dokter-untuk-tanamanmu-yang-sedang-sakit.html">
                      <GatsbyImage
                        image={this.props.data.LogoKabarTani.childImageSharp.gatsbyImageData}
                        alt = "logo-KabarTani" />
                    </a>
                  </div>
                  
                  <div className = "sponsor-img">
                    <GatsbyImage
                      image={this.props.data.LogoKompas.childImageSharp.gatsbyImageData}
                      alt = "logo-Kompas" />
                  </div>
                  
                  <div className = "sponsor-img">
                    <a href = "https://www.cnnindonesia.com/teknologi/20180405071902-185-288384/aplikasi-dokter-taniatawarkan-dokter-pribadi-untuk-tanaman">
                      <GatsbyImage
                        image={this.props.data.LogoCNN.childImageSharp.gatsbyImageData}
                        alt = "logo-LogoCNN" />
                    </a>
                  </div>

                </div>
              </div>
            </div>

            <div id = "section-6">
              <Row>
                <Col md = {{span : 5, offset : 1}} id = "section-6-col-1">
                  <div id = "section-6-col-1-title-1">Be a part of the future of agriculture</div>
                  <div id = "section-6-col-1-title-2">
                    Subscribe to news updates from Neurafarm
                  </div>
                  
                  <div id = "form-container">
                    <div id = "form-label"> Email * </div>
                    <form 
                        onSubmit={this._handleSubmit}
                        >
                      
                      <input 
                        type = "email" 
                        name = "email"
                        value = {this.state.email} 
                        onChange = {this.handleChange} 
                        id = "form-email"/>

                      <input type="submit" value="Submit" id = "form-submit"/>
                    
                    </form>
                    
                    <div 
                      id = "form-feedback"
                      style = {{display : this.state.result == null ? "none" : "block"}}
                    >
                      {this.state.resultMsg}
                    </div>
                    
                  </div>
                </Col>

                <Col md = {{span : 4, offset : 2}} id = "section-6-col-2">
                    <img src = {Vegetables} alt = "vegetables-img" id = "vegetables-img"/>
                    <img src = {OrangeTree} alt = "OrangeTree-img" id = "orange-tree-img"/>
                    <img src = {Cabbages} alt = "cabbages-img" id = "cabbages-img"/>
                </Col>
              </Row>
            </div>

            <div id = "section-7">
              <div id = "section-7-container">
                <div id = "section-7-title">Increase Yield and Productivity Today</div> 
                <div id = "section-7-link-container">
                  <div id = "section-7-link-1">
                    <Link to = "/what-we-do">Get Started</Link>
                  </div>
                  <div id = "section-7-link-2">
                    <Link to = "/contact-us">Talk to Us</Link>
                  </div>
                </div>               
              </div>
            </div>

          </div>
        </div>
      </Layout>
    ); 
  }
}

export default Home;

